<template>
  <v-navigation-drawer v-model="isOpen" app>
    <v-sheet class="pa-4 text-center">
      <base-profile-avatar :user="user"></base-profile-avatar>

      <div>{{ displayName }}</div>
      <div class="mt-4">
        Available Budget {{ new Date().getFullYear() }}:
        <v-chip outlined color="primary" class="ma-2"
          >{{ userCurrentBudget }} $</v-chip
        >
      </div>
    </v-sheet>

    <v-divider></v-divider>

    <v-list>
      <v-list-item
        v-for="[icon, text, action] in links"
        :key="icon"
        link
        @click="action"
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TheSideMenu',

  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    BaseProfileAvatar: () => import('@/components/BaseProfileAvatar'),
  },

  data: (self) => ({
    userBudgetOverview: null,
    links: [['mdi-logout', 'Logout', self.logout]],
  }),

  computed: {
    ...mapState('auth', ['user']),
    displayName() {
      return this.user?.displayName;
    },
    userCurrentBudget() {
      return this.userBudgetOverview
        ? this.userBudgetOverview.amount - this.reducedApprovedCourses
        : 0;
    },
    reducedApprovedCourses() {
      return this.userBudgetOverview
        ? this.userBudgetOverview.courses.reduce((acc, course) => {
            acc += Number(course.amount);
            return acc;
          }, 0)
        : 0;
    },
  },

  async mounted() {
    this.fetchBudgetInfo();
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('budgets', ['getAvailableBudget']),
    async fetchBudgetInfo() {
      const { data } = await this.getAvailableBudget({
        employeeId: this.user.uid,
        year: new Date().getFullYear(),
      });
      this.userBudgetOverview = data.length ? data[0] : null;
    },
  },
};
</script>
